export const reqNutritionist_mealPlans_getDetail = async(axios, { id }) => {
  return axios.get(`/v1/nutritionist/meal-plans/${id}`)
}

export const reqMealPlans_sendToClientManager = async(axios, { id, clientManagerId, note }) => {
  return axios.post(`v1/nutritionist/meal-plans/review/${id}`, {
    clientManagerId: clientManagerId || null,
    note: note || null,
  })
}

export const reqNutritionist_mealPlans_getOptionList = async(axios, { programId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/${programId}/active`)
}

export const reqNutritionist_mealPlans_getClientManager = async(axios) => {
  return axios.get('/v1/nutritionist/meal-plans-client-manager')
}

export const reqNutritionist_mealPlans_preview = async(
  axios, {
    clientId,
    programId,
    month,
  }) => {
  return axios.get('/v1/nutritionist/meal-plans/preview', {
    params: {
      clientId,
      programId,
      month,
    },
  })
}

export const reqNutritionist_mealPlans_noteHistory = async(axios, { id }) => {
  return axios.get(`/v1/nutritionist/meal-plans/note-history/${id}`)
}

export const reqNutritionist_mealPlans_specificNote = async(
  axios, {
    clientId,
  }) => {
  return axios.get(`/v1/nutritionist/meal-plans/specific-note?clientId=${clientId}`)
}

export const reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus = async(
  axios, {
    id,
  }) => {
  return axios.post(`/v1/nutritionist/meal-plans/menu-recommendation/bulk-create-status/${id}`)
}